<template>
  <view-item title="领退料明细">
    <template #operation>
      <button-download
        :link="false"
        :ajax="{ action: 'GET /enocloud/service/material/history/export', params: (params) => (params.payload = table.$ajaxParams.payload) }"
      >
        导出
      </button-download>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <table-dynamic
            code="SVCMRDTFD"
            :data="table.data"
            :loading="table.loading"
            :height="height"
            :method="table.get"
            pagination
            :paging="table.paging"
            show-summary
            :summary="table.summary.data"
            :config="table.config"
          ></table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/material/history/query',
            summary: 'GET /enocloud/service/material/history/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          SUB_TYPE: { header: { filter: { type: 'text', field: 'inventoryHistory.subType' } } },
          SERVICE_SERIAL_NO: { header: { filter: { type: 'text', field: 'serviceSerialNo' } } },
          GOODS_NAME: { header: { filter: { type: 'text', field: 'goodsName' } } },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/warehouse',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          WORKING_TEAM_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'pickedWorkingTeamId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          PICKED_BY: { header: { filter: { type: 'text', field: 'pickedBy' } } },
          SERVICE_SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['startSettlementDatetime', 'endSettlementDatetime'], props: { type: 'daterange' } } }
          },
          SENDER_TELEPHONE: {
            header: { filter: { type: 'text', field: 'senderTelephone' } }
          },
          SETTLEMENT_AUDIT_COMMENT: {
            header: { filter: { type: 'text', field: 'settlementAuditComment' } }
          },
          GOODS_SERIAL_NO: { header: { filter: { type: 'text', field: 'goodsSerialNo' } } },
          GOODS_OEM: { header: { filter: { type: 'text', field: 'oem' } } },
          GOODS_MODEL: { header: { filter: { type: 'text', field: 'goodsModel' } } },
          SERVICE_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          GOODS_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'inventoryHistory.inventory.categoryName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/goods/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          BATCH_NO: { header: { filter: { type: 'text', field: 'inventoryHistory.inventory.batchNo' } } },
          SERVICE_CHARGING_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'chargingMethodCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['CHGMTD']
                      params.payload = { excludes: ['TNM', 'RMN', 'SBT', 'RWK'] }
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceAdvisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } },
          VEHICLE_PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name' })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'vehicleSpec' } } },
          INVENTORY_HISTORY_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'inventoryHistoryTypeCode',
                props: {
                  options: [
                    { message: '领料', code: 'MRQ' },
                    { message: '退料', code: 'MRQR' },
                    { message: '领料退货', code: 'MRQRJ' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          VEHICLE_VIN: { header: { filter: { type: 'text', field: 'vin' } } },
          WAREHOUSE_SHELF: { header: { filter: { type: 'text', field: 'inventoryHistory.inventory.warehouseShelf' } } },
          SALESMAN_NAME: { header: { filter: { type: 'text', field: 'serviceGoods.salesmanName' } } },
          SERVICE_PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['startDatetime', 'endDatetime'], props: { type: 'daterange' } } } },
          SERVICE_CREDIT_DATETIME: {
            header: { filter: { type: 'date', field: ['creditStartDate', 'creditEndDate'], props: { type: 'daterange' } } }
          },
          SERVICE_SETTLE_DATETIME: {
            header: { filter: { type: 'date', field: ['settleStartDate', 'settleEndDate'], props: { type: 'daterange' } } }
          },
          SERVICE_GOODS_COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          SERVICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceStatusCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['SVCSTAT']
                      params.payload = { excludes: ['PR', 'DC'] }
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          GOODS_VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'goodsVehicleSpec' } } },
          SERVICE_PREPARED_BY_NAME: { header: { filter: { type: 'text', field: 'preparedByName' } } },
          PURCHASE_SERIAL_NO: { header: { filter: { type: 'text', field: 'purchaseNos' } } },
          PICKED_DATETIME: {
            header: { filter: { type: 'date', field: ['startMaterialDatetime', 'endMaterialDatetime'], props: { type: 'daterange' } } }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
